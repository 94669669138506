<template>
  <v-card
    class="
      card-shadow
      border-radius-xl
      px-4
      py-4
      w-100
      justify-space-between
      align-center
    "
  >
    <v-row class="justify-space-between py-4 px-4">
      <div>
        <h4>Parking principal</h4>
        <p>Liste des places et véhicules</p>
      </div>
      <v-btn
        :elevation="0"
        color="#cb0c9f"
        class="font-weight-bolder btn-danger bg-gradient-info py-4 px-4"
        small
        @click="convertToXLS"
      >
        EXPORTER LA TABLE
      </v-btn>
    </v-row>
    <div class="d-flex align-center justify-space-between">
      <div>
        <label class="text-xs text-typo font-weight-bolder ms-1"
          >Type de véhicule:</label
        >
        <v-select
          v-model="selectedVehicle"
          :items="vehicleList"
          value="Furniture"
          color="rgba(0,0,0,.6)"
          class="
            input-style
            font-size-input
            text-light-input
            placeholder-light
            border-radius-md
            select-style
            mt-2
            mb-0
          "
          outlined
          single-line
          height="36"
        >
        </v-select>
      </div>
      <div class="w-20">
        <v-checkbox
          v-model="onlyOccupiedPlace"
          label="Afficher uniquement les places occupées"
        ></v-checkbox>
      </div>
      <div class="w-20">
        <v-checkbox
          v-model="onlyFreePlace"
          label="Afficher uniquement les places disponibles"
        ></v-checkbox>
      </div>
      <div>
        <v-text-field
          v-model="search"
          rounded-sm
          hide-details
          outlined
          background-color="rgba(255,255,255,.9)"
          color="rgba(0,0,0,.6)"
          light
          placeholder="Rechercher..."
          class="
            input-style
            font-size-input
            placeholder-lighter
            input-alternative input-icon
            border
          "
        >
          <template slot="prepend-inner">
            <v-icon color="rgba(0,0,0,.6)" size=".875rem">fas fa-search</v-icon>
          </template>
        </v-text-field>
      </div>
    </div>
    <!-- TABLE DETAILS -->
    <div v-if="loading" class="grid-center-h-v">
      <v-progress-circular
        :size="32"
        :width="2"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <DashboardDetailsCardTable
      v-else
      :spots="filteredSpots"
      @updateData="getListSpots"
    ></DashboardDetailsCardTable>
  </v-card>
</template>

<script>
import axios from "axios";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import DashboardDetailsCardTable from "./DashboardDetailsCardTable.vue";

export default {
  name: "DashboardDetailsCard",
  components: {
    DashboardDetailsCardTable,
  },
  data() {
    return {
      loading: false,
      selectedVehicle: "voiture",
      vehicleList: ["voiture", "moto", "vélo"],
      spots: [],
      onlyOccupiedPlace: false,
      onlyFreePlace: false,
      search: "",
    };
  },

  computed: {
    filteredSpots() {
      let filtered = this.spots;
      if (this.onlyFreePlace) {
        filtered = this.spots.filter((spot) => spot.status === "free");
      }
      if (this.onlyOccupiedPlace) {
        filtered = filtered.filter(
          (spot) => spot.status === "occupied" || spot.status === "reserved"
        );
      }
      if (this.selectedVehicle) {
        const selectedVehicule = this.selectedVehicle.toLowerCase();
        filtered = filtered.filter((spot) => {
          if (spot.vehicleType === undefined) return false;
          if (selectedVehicule === "voiture") {
            return spot.vehicleType.toLowerCase().startsWith("car");
          }
          if (selectedVehicule === "moto") {
            return spot.vehicleType.toLowerCase().startsWith("moto");
          }
          if (selectedVehicule === "vélo") {
            return spot.vehicleType.toLowerCase().startsWith("bi");
          }
        });
      }
      if (this.search) {
        const dataToScan = [
          "plateNumber",
          "spotnumber",
          "typologie",
          "username",
          "useremail",
        ];
        filtered = filtered.filter((elm) => {
          return dataToScan.some((k) => {
            return (
              elm[k] && elm[k].toLowerCase().includes(this.search.toLowerCase())
            );
          });
        });
      }
      return filtered;
    },
  },

  beforeMount() {
    this.getListSpots();
  },

  methods: {
    getListSpots() {
      const parkingid = this.$route.params.id;
      this.loading = true;
      axios
        .get(`${this.$store.getters.apiurl}/listspots/${parkingid}`)
        .then((res) => {
          this.spots = res.data.map((spot) => {
            return {
              ...spot,
              username:
                `${spot.user?.surname ?? ""} ${spot.user?.name ?? ""}` ?? "_",
              useremail: spot.user?.email ?? "_",
            };
          });
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.error("Fetch spot list ERROR", err);
        });
    },

    convertToXLS() {
      let spreadsheet = [];
      this.filteredSpots.forEach((data) => {
        let row = [
          data.spotnumber,
          data.plateNumber,
          data.username,
          data.useremail,
        ];
        spreadsheet.push(row);
      });
      this.createExcelFile(spreadsheet, "Liste des places");
    },

    createExcelFile(dataToXls, title = "tableau", subject = "export") {
      let wb = XLSX.utils.book_new();
      wb.Props = {
        Title: title,
        Subject: subject,
        Author: "Dynapark",
        CreatedDate: new Date(),
      };
      wb.SheetNames.push(title);
      let ws_data = dataToXls;
      let ws = XLSX.utils.aoa_to_sheet(ws_data);
      wb.Sheets[title] = ws;
      var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }
      saveAs(
        new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
        `${title}-${subject}.xlsx`
      );
    },
  },
};
</script>

<style scoped></style>
