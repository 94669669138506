<template>
  <v-card>
    <v-card-text class="px-0 py-0">
      <!-- NEW DATA TABLE -->
      <v-data-table
        :headers="headers"
        :items="spots"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        fixed-header
        height="70vh"
        class="table"
        hide-default-footer
        mobile-breakpoint="0"
        :items-per-page="users.length"
      >
        <template v-slot:[`item.id`]="{ item }">
          <div class="d-flex align-center">
            <v-checkbox
              v-model="item.checkbox"
              :ripple="false"
              color="#141727"
            ></v-checkbox>
          </div>
        </template>

        <template v-slot:[`item.spot`]="{ item }">
          {{ item.spot === "libre" ? "" : item.spotnumber }}
        </template>

        <template v-slot:[`item.plateNumber`]="{ item }">
          <div>
            <p class="text-sm text-secondary font-weight-bold mt-4 mb-0">
              {{ item.plateNumber }}
            </p>
          </div>
        </template>

        <template v-slot:[`item.username`]="{ item }">
          <div>
            <p class="text-sm text-secondary mb-0">
              {{ item.username }}
            </p>
            <p class="text-sm text-secondary mb-0">
              {{ item.useremail }}
            </p>
          </div>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <p
            v-if="item.status === 'occupied'"
            class="
              text-sm text-secondary
              mb-0
              text-decoration-underline
              action-row
            "
            @click="actionRow(item, 'FREE')"
          >
            Libérer la place
          </p>
          <div
            v-else-if="item.status === 'reserved'"
            class="text-sm text-secondary mb-0"
          >
            <p class="font-weight-bold mb-0">PLACE RESERVÉE</p>
            <p
              class="
                text-sm text-secondary
                mb-0
                text-decoration-underline
                action-row
              "
              @click="actionRow(item, 'REMOVE')"
            >
              Annuler la reservation
            </p>
          </div>

          <!-- FORM TAKE PLACE -->
          <div v-else-if="item.status == undefined || item.status === 'free'">
            <p
              class="
                text-sm text-secondary
                mb-0
                text-decoration-underline
                action-row
              "
              @click="openDialog(item)"
            >
              Occuper la place
            </p>
          </div>
        </template>
      </v-data-table>
    </v-card-text>

    <!-- DIALOG TAKE PLACE -->
    <v-dialog v-model="displayFormTake" width="600">
      <template> </template>
      <v-card class="card-shadow border-radius-xl px-4 py-4">
        <v-card-title class="text-h5 px-0">AJOUTER UN VÉHICULE</v-card-title>
        <v-row>
          <v-col cols="12">
            <label class="text-xs text-typo font-weight-bolder ms-1"
              >Pour</label
            >
            <v-select
              v-model="selectedUser"
              :items="users"
              item-text="fullname"
              item-value="id"
              color="rgba(0,0,0,.6)"
              class="
                input-style
                font-size-input
                text-light-input
                placeholder-light
                border-radius-md
                select-style
                mt-2
                mb-0
              "
              outlined
              single-line
              height="36"
            >
            </v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <label class="text-xs text-typo font-weight-bolder ms-1"
              >Véhicule</label
            >
            <v-select
              v-model="selectedVehicle"
              :items="getVehicules"
              item-text="content"
              item-value="immat"
              color="rgba(0,0,0,.6)"
              class="
                input-style
                font-size-input
                text-light-input
                placeholder-light
                border-radius-md
                select-style
                mb-0
              "
              outlined
              single-line
              height="36"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex justify-space-between">
            <v-btn
              elevation="0"
              color="#fff"
              class="font-weight-bolder btn-outline-default py-4 px-2 mr-4"
              small
              @click="cancelTake"
            >
              ANNULER
            </v-btn>
            <v-btn
              :elevation="0"
              color="#cb0c9f"
              class="
                font-weight-bolder
                btn-danger
                bg-gradient-info
                py-4
                px-8
                mt-auto
              "
              small
              :disabled="!selectedUser && !selectedVehicle"
              @click="actionRow(dialogItem, 'TAKE')"
            >
              VALIDER
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  name: "DashboardDetailsCardTable",

  props: {
    spots: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      displayFormTake: false,
      dialogItem: null,
      users: [],
      selectedVehicle: null,
      selectedUser: null,
      sortBy: "spot",
      sortDesc: false,
      headers: [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "id",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        {
          text: "PLACE",
          value: "spot",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "PLAQUE",
          value: "plateNumber",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "UTILISATEUR",
          value: "username",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "",
          value: "action",
          class: "text-secondary font-weight-bolder opacity-7",
        },
      ],
    };
  },

  beforeMount() {
    const getUsers = axios.get(`${this.$store.getters.apiurl}/users`);
    const getParkings = axios.get(`${this.$store.getters.apiurl}/parkings`);
    axios
      .all([getUsers, getParkings])
      .then(
        axios.spread((...responses) => {
          const resUsers = responses[0];
          const resParkings = responses[1];
          this.users = resUsers.data.map((user) => {
            return {
              fullname: `${user.name || "..."} ${user.surname || "..."}`,
              ...user,
            };
          });
          this.parkings = resParkings.data;
          this.pendingRequest = false;
        })
      )
      .catch((err) => {
        this.pendingRequest = false;
        console.error("Get users & parkings ERROR: ", err);
      });
  },

  computed: {
    getVehicules() {
      const user = this.users
        ? this.users.find((user) => user.id === this.selectedUser)
        : null;
      return user && user.plates ? this.buildUserVehicleList(user.plates) : [];
    },
  },

  methods: {
    /**
     * POST UPDATE ON ROW
     *
     * @param {object} data
     * @param {('FREE' | 'TAKE' | REMOVE)} actionType
     */
    actionRow(data, actionType) {
      const userid = actionType === "TAKE" ? this.selectedUser : data.userid;
      const plateNumber =
        actionType === "TAKE" ? this.selectedVehicle : data.plateNumber;
      const body = {
        parkingid: this.$route.params.id,
        action: actionType,
        resaid: data.resaid,
        status: data.status,
        vehicleType: data.vehicleType,
        spotnumber: data.spotnumber,
        plateNumber,
        userid,
        id: data.id || "",
      };
      // -- Clean body before POST
      for (const [key, value] of Object.entries(body)) {
        if (value === undefined || value === "") {
          delete body[key];
        }
      }
      axios
        .post(`${this.$store.getters.apiurl}/listspots`, body)
        .then((res) => {
          console.log("Updated row", res.data);
          this.$emit("updateData");
        })
        .catch((err) => {
          console.error("Update row ERROR: ", err);
        });
    },

    openDialog(item) {
      console.log("OPEN DIALOG DATA");
      this.dialogItem = item;
      this.displayFormTake = true;
    },

    cancelTake() {
      this.selectedUser = null;
      this.selectedVehicle = null;
      this.displayFormTake = false;
    },

    buildUserVehicleList(plates) {
      if (!plates || plates.length === 0) {
        return [];
      }
      const vehicleName = {
        cars: "Voiture",
        motorbike: "Moto",
        bike: "Vélo",
        default: "Véhicule",
      };

      let vehicleList = [];
      const vehicleTypes = Object.keys(plates);
      vehicleTypes.forEach((k) => {
        const typeColl = plates[k].map((immat) => {
          return {
            type: k,
            immat: immat,
            content: `${vehicleName[k] ?? vehicleName.default} immat: ${immat}`,
          };
        });
        vehicleList = [...vehicleList, ...typeColl];
      });
      return vehicleList;
    },
  },
};
</script>

<style>
.action-row:hover {
  cursor: pointer;
}
</style>
