<template>
  <div class="container">
    <v-row>
      <v-col cols="12">
        <BtnRetour />
        <GestionCourante></GestionCourante>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <DashboardDetailsCard></DashboardDetailsCard>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import GestionCourante from "../../components/Dynapark/GestionCourante.vue";
import DashboardDetailsCard from "../../components/Dynapark/DashboardDetailsCard.vue";
import BtnRetour from "../../components/Dynapark/BtnRetour.vue";

export default {
  name: "TableauDeBordDetails",
  components: {
    GestionCourante,
    DashboardDetailsCard,
    BtnRetour,
  },
};
</script>

<style scoped></style>
